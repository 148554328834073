import React from 'react'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'

const WhoWeArePageSection0 = ({ data }) => {
  if (!data) {
    return ''
  }

  const isMobile = useBreakpoint('sm', false)

  return (
    <div
      className='content-container' style={{
        height: isMobile ? 'unset' : `calc(100vh - 86px)`,
        minHeight: '560px',
        marginTop: 86,
        backgroundImage: data.background_image ? `url(${data.background_image.url})` : null,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
      <div className='content-container-horizontal' style={{ display: 'flex', flexDirection: 'column', paddingBottom: '108px' }}>
        <div className='d-flex flex-column'>
          {data.heading ? <Fade bottom><h4
            className='quint-heading-big font-main color-primary' style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '16px',
              textAlign: 'center'
            }}>{data.heading}</h4></Fade> : ''}
        </div>
        <div className='d-flex flex-column'>
          {data.content && data.content.split('\n').flatMap((text, i) => {
            return (
              <Fade bottom key={i}><p
                className='font-main quint-heading color-primary' style={{
                  textAlign: 'center',
                  fontWeight: 'normal'
                }}>
                {text}
              </p></Fade>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default WhoWeArePageSection0
