import React from 'react'
import { cvModalOpen } from '../CVModal'
import { Fade } from 'react-reveal'
import { LoadableQuintButton, LoadableQuintMediaContainer } from '../LoadableComponents'
import useBreakpoint from '../../customHooks/useBreakpoint'

const WhoWeArePageCVSection = ({ data }) => {
  if (!data) {
    return ''
  }

  const isMobile = useBreakpoint('sm', false)

  return (
    <div className='content-container' style={{ minHeight: isMobile ? 554 : 660 }}>
      <div className='content-container-horizontal'>
        {data.media &&
          <LoadableQuintMediaContainer
            type={data.media.document.data.type}
            path={data.media.document.data.file.url}
            mediaSpeed={data.media.document.data.speed}
            style={{ height: '100%', maxHeight: '100vh', width: '40%', marginRight: '5%' }}
            className='display-none-on-small'
          />}
        <div className='d-flex flex-column' style={{ maxWidth: isMobile ? '100%' : '40%', padding: isMobile ? '0 40px' : 0 }}>
          {data.heading &&
            <Fade bottom><h2
              className='quint-heading font-main color-primary'
              style={{ marginBottom: '42px' }}
            >
              {data.heading}
            </h2></Fade>}
          {data.subtitle && data.subtitle.split('\n').flatMap((text, i) => {
            return (
              <Fade bottom key={i}>
                <p
                  className='paragraph font-main color-primary-dark'
                  style={isMobile ? { textAlign: 'center' } : {}}>
                  {text}
                </p>
              </Fade>
            )
          })}
          {data.button &&
            <>
              {isMobile
                ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <LoadableQuintButton
                    onClick={() => cvModalOpen()}
                    key='quint-btn'
                    paddingX='32px'
                    rounded='pill'
                    textColor='#FFFFFF'
                    background='#25317B'
                    border={false}
                    style={{ marginTop: '64px' }}>
                    {data.button}
                  </LoadableQuintButton>
                </div>
                : <Fade bottom>
                  <LoadableQuintButton
                    onClick={() => cvModalOpen()}
                    key='quint-btn'
                    paddingX='32px'
                    rounded='pill'
                    textColor='#FFFFFF'
                    background='#25317B'
                    border={false}
                    style={{ marginTop: '64px' }}>
                    {data.button}
                  </LoadableQuintButton>
                </Fade>}
            </>}
        </div>
      </div>
    </div>
  )
}

export default WhoWeArePageCVSection
