import React, { useEffect, useRef, useState } from 'react'
import PersonComponent from '../PersonComponent'
import ArrowDown from '../../assets/img/arrow-down.svg'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { LoadableQuintCarousel, LoadableQuintTabAnimated } from '../LoadableComponents'
import { isEqual } from 'lodash'
import { PersonModalComponent, PersonModalComponentMobile } from '../PersonModalComponent'

const fadeAnimationTiming = 300
const heightAnimationTiming = 200

const WhoWeArePagePeopleSection = ({ data }) => {
  if (!data) {
    return ''
  }

  const peopleContainer = useRef()

  const [selectedTab, setSelectedTab] = useState(undefined)
  const [selectedPerson, setSelectedPerson] = useState(undefined)
  const [modalDetails, setModalDetails] = useState(undefined)

  const isMobile = useBreakpoint('sm', false)

  function handlePersonSelection(person) {
    if (isEqual(selectedPerson, person) && isEqual(modalDetails, person)) {
      setSelectedPerson(undefined)
      window.setTimeout(() => {
        setModalDetails(undefined)
      }, fadeAnimationTiming + heightAnimationTiming)
    } else if (!isEqual(selectedPerson, person) && !isEqual(modalDetails, person)) {
      setSelectedPerson(person)
      setModalDetails(person)
    }
  }

  const tabsArray = data.groups.reduce((result, group) => {
    if (group.people_group && group.people_group.document) {
      result.push(
        {
          id: group.people_group.document.data.name.replace(/\s+/g, '-').toLowerCase(),
          text: group.people_group.document.data.name,
          image: group.people_group.document.data.icon.url,
        }
      )
    }
    return result
  }, [])

  function handleTabSelect(index) {
    if (index !== selectedTab) {
      peopleContainer.current.classList.add('animate')
      handlePersonSelection(undefined)
      window.setTimeout(() => {
        setSelectedTab(index)
        peopleContainer.current.classList.remove('animate')
      }, 500)
    }
  }

  useEffect(() => {
    if (tabsArray && Array.isArray(tabsArray) && tabsArray.length > 0) {
      setSelectedTab(tabsArray[0].id)
    }
  }, [])

  let selectedIndex = 0
  const mainPeople = []
  const mainPeopleGroups = []
  if (selectedTab) {
    selectedIndex = tabsArray.findIndex((el) => el.id === selectedTab)
    if (selectedIndex !== -1) {
      data.groups[selectedIndex].people_group.document.data.people_list.forEach((person) => {
        mainPeople.push(person.people.document.data)
      })
    }
    let i
    for (i = 0; i < mainPeople.length; i += 3) {
      const group = []
      group.push(mainPeople[i])
      if ((i + 1) < mainPeople.length) {
        group.push(mainPeople[i + 1])
      }
      if ((i + 2) < mainPeople.length) {
        group.push(mainPeople[i + 2])
      }
      mainPeopleGroups.push(group)
    }
  }

  return (
    <div className='content-container'>
      {data.heading
        ? <Fade bottom><span
          className='quint-heading font-main color-primary' style={{ marginTop: 32 }}>
          {data.heading}
        </span></Fade>
        : ''}
      <div className='content-container' style={{ alignItems: 'unset', padding: '16px' }}>
        <LoadableQuintTabAnimated tabs={tabsArray} onSelect={handleTabSelect} />
      </div>
      <div
        className='quint-subtitle font-main' style={{
          maxWidth: '896px',
          padding: '40px 56px',
          textAlign: 'center'
        }}>
        {data.groups[selectedIndex].people_group.document.data.description}
      </div>
      <div
        className='content-container padding-0 fade-container'
        ref={peopleContainer}>
        <div
          style={{
            flex: 1,
            width: '100%',
            display: isMobile ? 'initial' : 'flex',
            justifyContent: 'center'
          }}>
          {isMobile
            ? <div style={{ padding: '0 16px' }} key={'mobile-' + selectedTab}>
              {mainPeople.map((person, index) => (
                <div key={'f' + index}>
                  <PersonComponent
                    selected={isEqual(selectedPerson, person)}
                    onSelect={() => handlePersonSelection(person)}
                    data={person}
                    style={{ width: '100%', padding: '0 40px', marginBottom: 16 }} />
                  {isEqual(selectedPerson, person) &&
                    <PersonModalComponentMobile modalDetails={modalDetails} selectedPerson={selectedPerson} />}
                </div>
              ))}
            </div>
            : <LoadableQuintCarousel arrows indicatorsDisabled timerDisabled key={`desktop-${selectedTab}`} height='50vh' style={{ maxWidth: 1180, width: '100%' }}>
              {mainPeopleGroups.map((group, index) => {
                return (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1
                    }}
                    key={`${group} ${index}`}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        flex: 1,
                        maxWidth: 842
                      }}>
                      {mainPeopleGroups[index].map((person, ind) => {
                        return (
                          <PersonComponent
                            selected={isEqual(selectedPerson, person)}
                            onSelect={() => handlePersonSelection(person)}
                            key={`l${ind}${person.name}`}
                            data={person}
                            style={{ width: '30%' }} />
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </LoadableQuintCarousel>}
        </div>
      </div>
      <PersonModalComponent modalDetails={modalDetails} selectedPerson={selectedPerson} />
      <span className='quint-body font-main color-primary-dark' style={{ marginBottom: '32px' }}>
        scroll for more
      </span>
      <img
        className='bounce-simple animated medium infinite'
        src={ArrowDown}
        alt=''
        style={{ marginBottom: '16px' }}
      />
    </div>
  )
}

export default WhoWeArePagePeopleSection
