import React from 'react'
import { LoadableQuintFlipImage } from '../LoadableComponents'
import useBreakpoint from '../../customHooks/useBreakpoint'

const PersonComponent = ({ data, selected = false, onSelect, style, ...rest }) => {
  if (!data) {
    return ''
  }

  const isMobile = useBreakpoint('sm', false)

  const handleSelect = () => {
    if (onSelect) {
      onSelect()
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        ...style
      }} {...rest}>
      <div style={{ width: '100%', position: 'relative' }}>
        <div style={{ marginTop: '100%' }} />
        <LoadableQuintFlipImage
          image1={data.first_image.url}
          image2={data.second_image.url}
          direction='top'
          change='onSelect'
          selected={selected}
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        />
      </div>
      <div style={{
        backgroundColor: '#F7F7F7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: isMobile ? 'initial' : '1',
        width: '100%',
        padding: '8px 8px 22px 8px'
      }}>
        <div style={{ height: '100%', width: '70%', maxWidth: '70%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <span
            className='quint-subtitle font-main color-primary'
            style={{ display: 'block', width: '100%', fontWeight: 600, textAlign: 'start' }}>
            {data.name}
          </span>
          <span
            className='quint-subtitle-small font-main color-primary'
            style={{ display: 'block', width: '100%', textAlign: 'start', fontWeight: 'normal' }}>
            {selected ? data.personal_role : data.role}
          </span>
        </div>
        <div
          className={`person-icon ${selected ? 'selected' : ''}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '30%' }}
          onClick={handleSelect}>
          <svg width='86' height='86' viewBox='0 0 86 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M43 42.2C42.5582 42.2 42.2 42.5582 42.2 43C42.2 43.4418 42.5582 43.8 43 43.8V42.2ZM81.5657 43.5657C81.8781 43.2533 81.8781 42.7467 81.5657 42.4343L76.4745 37.3431C76.1621 37.0307 75.6556 37.0307 75.3431 37.3431C75.0307 37.6556 75.0307 38.1621 75.3431 38.4745L79.8686 43L75.3431 47.5255C75.0307 47.8379 75.0307 48.3444 75.3431 48.6569C75.6556 48.9693 76.1621 48.9693 76.4745 48.6569L81.5657 43.5657ZM43 43.8L81 43.8V42.2L43 42.2V43.8Z' />
            <rect x='28' y='28' width='30' height='30' rx='15' strokeWidth='1.6' strokeLinejoin='round' />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default PersonComponent
