import React from 'react'
import { LoadableQuintIconButton } from '../LoadableComponents'

const fadeAnimationTiming = 300
const heightAnimationTiming = 200

export const PersonModalComponent = (props) => {
  const { modalDetails, selectedPerson, ...rest } = props

  return (
    <div
      className='description display-none-on-small' style={{
        height: modalDetails ? 'fit-content' : '0',
        width: '100%',
        padding: '0 56px',
        backgroundClip: 'content-box',
        maxWidth: '924px',
        backgroundColor: '#F7F7F7',
        transform: !selectedPerson ? 'scaleY(0)' : 'scaleY(1)',
        transition: `transform ${heightAnimationTiming}ms`,
        transitionDelay: !selectedPerson ? (fadeAnimationTiming + 'ms') : '0ms',
        transformOrigin: 'top',
        marginBottom: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }} {...rest}>
      <div style={{
        opacity: selectedPerson ? '1' : '0',
        transition: `opacity ${fadeAnimationTiming}ms`,
        transitionDelay: selectedPerson ? (heightAnimationTiming + 'ms') : '0ms',
        width: '100%',
        padding: '32px 32px 10px 32px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <div
          className='quint-body font-main color-primary-dark' style={{
            fontWeight: 'normal',
            textAlign: 'center',
            marginBottom: '32px'
          }}>
          {modalDetails && modalDetails.description}
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'nowrap',
          alignItems: 'center',
          marginBottom: '32px'
        }}>
          {modalDetails && modalDetails.attributes.map((attribute, index) => (
            <div
              className='quint-body color-primary' style={{
                width: 'fit-content',
                border: '1px solid #B8C3F2',
                borderRadius: '500px',
                backgroundColor: 'white',
                padding: '12px 32px',
                whiteSpace: 'initial',
                marginBottom: 0
              }}
              key={'t' + index}>
              {attribute.attribute}
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row' }}>
          {modalDetails && modalDetails.value_attributes.map((valueAttribute, index) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', flex: '1', marginBottom: '32px' }}
              key={'u' + index}>
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginBottom: '6px'
                }}
                className='quint-heading-big font-supportive color-primary'>{valueAttribute.value}</div>
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'normal'
                }}
                className='quint-body color-primary'>{valueAttribute.attribute}</div>
            </div>
          ))}
        </div>
        <div>
          <div
            className='quint-subtitle font-main color-primary'>Follow {modalDetails && modalDetails.name}</div>
          <div style={{ borderBottom: '2px solid #B8C3F2', marginTop: '5px' }} />
          <div style={{ display: 'flex', marginTop: '5px' }}>
            {modalDetails && modalDetails.social.map((social, index) => (
              <img
                src={social.icon ? social.icon.url : null}
                className='button-no-outline'
                alt='quint-social-icons'
                style={{
                  height: 16,
                  marginRight: 6,
                  cursor: 'pointer'
                }} onClick={
                  social.url
                    ? () => window.open(social.url.url)
                    : null
                }
                key={'f' + index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const PersonModalComponentMobile = (props) => {
  const { modalDetails, selectedPerson, ...rest } = props

  return (
    <div {...rest}>
      <div
        className='description display-block-on-medium' style={{
          height: modalDetails ? 'fit-content' : '0',
          width: '100%',
          padding: '0 40px',
          backgroundClip: 'content-box',
          maxWidth: '924px',
          backgroundColor: '#F7F7F7',
          transform: !selectedPerson ? 'scaleY(0)' : 'scaleY(1)',
          transition: `transform ${heightAnimationTiming}ms`,
          transitionDelay: !selectedPerson ? (fadeAnimationTiming + 'ms') : '0ms',
          transformOrigin: 'top',
          marginBottom: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }} {...rest}>
        <div style={{
          opacity: selectedPerson ? '1' : '0',
          transition: `opacity ${fadeAnimationTiming}ms`,
          transitionDelay: selectedPerson ? (heightAnimationTiming + 'ms') : '0ms',
          width: '100%',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <div
            className='quint-body font-main color-primary-dark' style={{
              fontWeight: 'normal',
              textAlign: 'center',
              marginBottom: '20%'
            }}>
            {modalDetails && modalDetails.description}
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            alignItems: 'center',
            marginBottom: '16px'
          }}>
            {modalDetails && modalDetails.attributes.map((attribute, index) => (
              <div
                className='quint-body color-primary' style={{
                  width: 'fit-content',
                  border: '1px solid #B8C3F2',
                  borderRadius: '500px',
                  backgroundColor: 'white',
                  padding: '6px 18px',
                  whiteSpace: 'nowrap',
                  marginBottom: '14px'
                }}
                key={'t' + index}>
                {attribute.attribute}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
            {modalDetails && modalDetails.value_attributes.map((valueAttribute, index) => (
              <div
                style={{ display: 'flex', flexDirection: 'column', flex: '1', marginBottom: '10%' }}
                key={'u' + index}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: '6px'
                  }}
                  className='quint-heading-big font-supportive color-primary'>{valueAttribute.value}</div>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'normal'
                  }}
                  className='quint-body color-primary'>{valueAttribute.attribute}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className='description' style={{
          height: modalDetails ? 'fit-content' : '0',
          width: '100%',
          padding: '0 40px',
          backgroundClip: 'content-box',
          maxWidth: '896px',
          backgroundColor: '#F7F7F7',
          marginBottom: '32px',
          opacity: selectedPerson ? '1' : '0',
          transition: `opacity ${fadeAnimationTiming}ms`,
          transitionDelay: selectedPerson ? (heightAnimationTiming + 'ms') : '0ms',
        }}>
        <div style={{
          height: '100%',
          width: '100%',
          padding: '8px',
          display: 'flex',
          justifyContent: 'center'
        }}>
          {modalDetails && modalDetails.social.map((social, index) => (
            <LoadableQuintIconButton
              icon={social.icon ? social.icon.url : null}
              size={26}
              onClick={social.url ? () => window.open(social.url.url) : null}
              key={'f' + index} />
          ))}
        </div>
      </div>
    </div>
  )
}
