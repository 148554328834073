import React from 'react'
import WhoWeArePageSection0 from '../WhoWeArePageSection0'
import WhoWeArePagePeopleSection from '../WhoWeArePagePeopleSection'
import WhoWeArePageCVSection from '../WhoWeArePageCVSection'
import Seo from '../SEO'

const WhoWeAreTemplate = ({
  meta_title,
  meta_description,
  meta_image,
  whoWeAreSection0,
  whoWeAreCVSection,
  whoWeArePeopleSection
}) => (
  <>
    <Seo title={meta_title} description={meta_description} image={meta_image} />
    <WhoWeArePageSection0 data={whoWeAreSection0} />
    <WhoWeArePagePeopleSection data={whoWeArePeopleSection} />
    <WhoWeArePageCVSection data={whoWeAreCVSection} />
  </>
)

export default WhoWeAreTemplate
