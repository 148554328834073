import React from 'react'
import { graphql } from 'gatsby'
import WhoWeAreTemplate from '../components/WhoWeAreTemplate'

function createSectionData(data) {
  const dataObject = {}
  Object.keys(data).forEach(value => {
    const sectionName = value.split(/_(.+)/)[0]
    const keyName = value.split(/_(.+)/)[1]
    if (dataObject[sectionName]) {
      dataObject[sectionName][keyName] = data[value]
    } else {
      dataObject[sectionName] = {}
      dataObject[sectionName][keyName] = data[value]
    }
  })
  return dataObject
}

const WhoWeArePage = ({ data }) => {
  if (!data || !data.prismicWhoWeArePage) {
    return ''
  }
  const { data: pageData } = data.prismicWhoWeArePage
  const sectionData = createSectionData(pageData)

  return (
    <WhoWeAreTemplate
      meta_title={sectionData.meta ? sectionData.meta.title : null}
      meta_description={sectionData.meta ? sectionData.meta.description : null}
      meta_image={sectionData.meta && sectionData.meta.image ? sectionData.meta.image.url : null}
      whoWeAreSection0={sectionData.section1}
      whoWeAreCVSection={sectionData.cvsection}
      whoWeArePeopleSection={sectionData.peoplesection}
    />
  )
}

export default WhoWeArePage

export const whoWeAreQuery = graphql`
    query WhoWeArePage($id: String!) {
        prismicWhoWeArePage(id: { eq: $id }) {
            data {
                meta_title
                meta_description
                meta_image {
                  url
                }
                section1_heading
                section1_content
                section1_background_image {
                    url
                }
                peoplesection_heading
                peoplesection_groups {
                    people_group {
                        document {
                            ... on PrismicPeopleGroup {
                                id
                                data {
                                    name
                                    description
                                    icon {
                                        url
                                    }
                                    people_list {
                                        people {
                                            document {
                                                ... on PrismicPeople {
                                                    id
                                                    data {
                                                        first_image {
                                                            url
                                                        }
                                                        second_image {
                                                            url
                                                        }
                                                        name
                                                        role
                                                        personal_role
                                                        description
                                                        attributes {
                                                            attribute
                                                        }
                                                        social {
                                                            icon {
                                                                url
                                                            }
                                                            url {
                                                                url
                                                            }
                                                        }
                                                        value_attributes {
                                                            attribute
                                                            value
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                cvsection_heading
                cvsection_subtitle
                cvsection_button
                cvsection_media {
                    document {
                        ... on PrismicMedia {
                            id
                            data {
                                type
                                speed
                                file {
                                  url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
